const { addPageAction } = require('../util/new_relic.js');

const ANALYTICS_CONTEXT = {
  disableEvents: true,
  consumer: {
    name: 'Adobe Fonts',
    version: '1.0',
    platform: 'Web',
    device: 'na',
    os_version: 'na'
  }
};

/**
 * This component is responsible for loading and initializing the Adobe UniversalNav
 * header content.
 *
 * https://wiki.corp.adobe.com/display/CCHome/Universal+Nav+Integration+Guidelines
 *
 * NOTE: "localhost" is not whitelisted for any of the IMS related functionality.
 * If you want to test this functionality locally, you will need to use
 * https://localhost.adobe.com:3000/ instead of http://localhost:3000/.
 */
class UniversalNav extends HTMLElement {
  async connectedCallback() {
    const target = this.querySelector('#universal-nav-target');
    if (!target) {
      return;
    }

    try {
      await Promise.all([this._loadCSS(), this._loadScript()]);
      this._initUniversalNav(target);
    } catch (error) {
      if (this.getAttribute('env') !== 'prod') {
        console.error('There was an error loading UniversalNav:', error);
      }
    }
  }

  _getChildren() {
    const children = [];

    // Notifications will be gated behind a flipper flag until we have
    // approval to enable it in production.
    if (this.hasAttribute('enable-notifications')) {
      children.push({
        name: 'notifications',
        attributes: {
          notificationsConfig: {
            applicationContext: {
              appID: this.getAttribute('ansAppId')
            }
          }
        }
      });
    }

    children.push({
      name: 'app-switcher',
      attributes: {}
    }, {
      name: 'profile',
      attributes: {
        callbacks: {
          // UniversalNav doesn't handle the logic for signing in
          // so we need to provide the implementation here.
          onSignIn: () => {
            if (window.adobeIMS) {
              addPageAction('typekit.click.sign-in');
              window.adobeIMS.signIn();
            }
          }
        },

        componentLoaderConfig: {
          config: {
            enableLocalSection: true
          }
        }
      }
    });

    return children;
  }

  _initUniversalNav(target) {
    // eslint-disable-next-line new-cap
    window.UniversalNav({
      target,
      env: this.getAttribute('env'),
      analyticsContext: ANALYTICS_CONTEXT,
      theme: 'light',
      locale: this.getAttribute('locale'),
      children: this._getChildren(),
      imsClientId: this.getAttribute('ims-client-id'),
      isSectionDividerRequired: false
    });
  }

  _loadScript = () => {
    if (!this.hasAttribute('js')) {
      return;
    }

    const script = document.createElement('script');
    script.src = this.getAttribute('js');

    return new Promise((resolve, reject) => {
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
      document.head.appendChild(script);
    });
  }

  _loadCSS = () => {
    if (!this.hasAttribute('css')) {
      return;
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = this.getAttribute('css');

    return new Promise((resolve, reject) => {
      link.addEventListener('load', resolve);
      link.addEventListener('error', reject);
      document.head.appendChild(link);
    });
  }
}

customElements.define('af-universal-nav', UniversalNav);
