/**
 * @ngInject
 */
function AdobeFontsFooterController($window, I18nService) {
  var self = this;
  self.$window = $window;
  self.I18nService = I18nService;

  self.I18nService.getLocaleInfo().then(function(localeInfo) {
    self.locales = localeInfo.allLocales;
    self.selectedLocale = localeInfo.selectedLocale;
  });
}

/**
 * Handle the click on a locale button for change of locale.
 *
 * (This handler is for the old non-spectrum locale switcher. Once the new spectrum
 * version has launched, we can get rid of this method).
 */
AdobeFontsFooterController.prototype.handleLocaleChangeClick = function(locale) {
  this._setLocale(locale);
};

AdobeFontsFooterController.prototype.handleLocaleSwitcherChange = function() {
  this._setLocale(this.selectedLocale);
};

AdobeFontsFooterController.prototype._setLocale = function(locale) {
  var self = this;
  self.I18nService.setLocale(locale).then(function() {
    self.$window.location.reload(true);
  });
};


module.exports = AdobeFontsFooterController;
