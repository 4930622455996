/**
 * Directive for a locale switcher.
 * @ngInject
 */
function AdobeFontsLocaleSwitcher() {
  return {
    restrict: 'E',
    scope: {},
    bindToController: {
      locales: '&'
    },

    link: function(scope, element, attrs, controllers) {
      var localeSwitcherCtrl = controllers[0];
      var ngModel = controllers[1];
      localeSwitcherCtrl.ngModel = ngModel;

      localeSwitcherCtrl.localeList = element[0].querySelector('.adobe-fonts-locale-switcher__locale-items');
      localeSwitcherCtrl.localeSwitcherButton = element[0].querySelector('.adobe-fonts-locale-switcher__popover-button');
    },

    controller: require('./controller.js'),
    controllerAs: 'adobeFontsLocaleSwitcherCtrl',
    require: ['adobeFontsLocaleSwitcher', '?ngModel'],
    templateUrl: '/angular_templates/directives/adobe_fonts_locale_switcher.html'
  };
}

module.exports = AdobeFontsLocaleSwitcher;
