const dropdownUtil = require('../../../util/dropdown_util.js');
/**
 * @ngInject
 */
function AdobeFontsLocaleSwitcherController($scope, $window) {
  this.$scope = $scope;
  this.$window = $window;
  this.isOpen = false;
}

AdobeFontsLocaleSwitcherController.prototype.getLabel = function() {
  var locales = this.locales();
  for (var i = 0; i < locales.length; i++) {
    if (locales[i].value === this.getValue()) {
      return locales[i].label;
    }
  }
};

AdobeFontsLocaleSwitcherController.prototype.getValue = function() {
  if (this.ngModel) {
    return this.ngModel.$viewValue;
  }
};

AdobeFontsLocaleSwitcherController.prototype.handleToggle = function(event) {
  event.stopPropagation();

  var self = this;
  if (!this.isOpen) {
    this.$window.addEventListener('click', function() {
      self.$scope.$apply(function() {
        self.isOpen = false;
      });
    }, { once: true });

    this.$window.addEventListener('keydown', function() {
      if (event.key === 'Escape') {
        self.$scope.$apply(function() {
          self.isOpen = false;
        });
      }
    }, { once: true });
  }

  this.isOpen = !this.isOpen;
};

AdobeFontsLocaleSwitcherController.prototype.isSelected = function(locale) {
  return locale.value === this.getValue();
};

AdobeFontsLocaleSwitcherController.prototype.updateValue = function(locale) {
  if (this.ngModel) {
    this.ngModel.$setViewValue(locale.value);
  }
};

/**
 * Make the locale switcher popover navigable using the dropdown util
 * The escape & tab keys should close the popover and return focus to the popover button
 *
 * @param {*} event
 * @return nothing
 */
AdobeFontsLocaleSwitcherController.prototype.navigateLocale = function(event) {
  if (event.key === 'Escape' || event.key === 'Tab') {
    return this.handleToggle(event);
  }

  dropdownUtil.navigateDropdown(event, this.localeSwitcherButton, this.localeList);
};

/**
 * The locale switcher's menu of locale buttons are disabled and removed from the tab order when the menu is closed,
 * they are placed into the tab order when the menu is open
 *
 * @returns nothing
 */
AdobeFontsLocaleSwitcherController.prototype.isButtonItemsDisabled = function() {
  return !this.isOpen;
};

module.exports = AdobeFontsLocaleSwitcherController;
